<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Customer</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Origin</label>
                    <v-combobox
                        v-model="customerRequest.customerOriginSelected"
                        :items="listCustomerOrigin"
                        :rules=[validations.required]
                        item-text="description"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="customerRequest.name" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-tabs
                v-model="tab"
                fixed-tabs
            >
                <v-tab key="1">
                    Address
                </v-tab>
                <v-tab key="2">
                    Contacts
                </v-tab>
                <v-tab key="3">
                    Notes
                </v-tab>
                <v-tab key="4">
                    Documents
                </v-tab>
                <v-tab key="5">
                    History
                </v-tab>

                <v-tab key="7" @click="getCalendarHistoryRegister()">
                    Calendar History
                </v-tab>
            </v-tabs>
            
            <v-tabs-items v-model="tab">
                
                <!-- ADDRESS -->
                <v-tab-item key="1">
                    <br />

                    <v-row>
                        <v-col
                            style="text-align: right"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="showDialogAddressAndClearFields()"
                                    >
                                        Add New Address
                                    </v-btn>
                                </template>
                                <span>Add New Address</span>
                            </v-tooltip>
                        </v-col>
                        
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersAddress"
                                :items="customerRequest.listAddressRequest"
                                no-data-text="No address registered"
                                hide-default-footer
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.id" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true
                                        }"
                                        @customActionEdit="showDialogAddressAndFillFields"
                                        @confirmDelete="confirmAddressDelete" />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-dialog
                        v-model="showDialogAddress"
                        transition="dialog-top-transition"
                        persistent
                        width="800"
                        :fullscreen="$vuetify.breakpoint.mobile"
                    >
                    
                        <v-card>
                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                Address
                            </v-card-title>

                            <v-card-text style="margin-top: 20px;">
                    
                                <v-form
                                    ref="formAddress"
                                    v-model="validFormAddress"
                                    lazy-validation
                                >
                                    <v-row>
                                        <v-col 
                                            cols="4"
                                            lg="3"
                                            md="3"
                                            sm="4"
                                        >
                                            <label>Zip Code</label>
                                            <v-text-field
                                                v-model="zipCode"
                                                :rules=[validations.required]
                                                v-mask="'#########'"
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col 
                                            cols="8"
                                            lg="9"
                                            md="9"
                                            sm="8"
                                        >
                                            <label>Address</label>
                                            <v-text-field
                                                v-model="address" 
                                                :rules=[validations.required]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col  
                                            cols="4"
                                            lg="3"
                                            md="3"
                                            sm="4"
                                        >
                                            <label>State</label>
                                            <v-combobox
                                                v-model="stateSelected"
                                                :items="listState"
                                                :rules=[validations.required]
                                                item-text="codeDescription"
                                                item-value="id"
                                                clearable
                                                outlined
                                                @change="getListCityByState()"
                                                dense
                                            ></v-combobox>
                                        </v-col>
                                        <v-col  
                                            cols="8"
                                            lg="5"
                                            md="5"
                                            sm="8"
                                        >
                                            <label>City</label>
                                            <v-combobox
                                                v-model="citySelected"
                                                :items="listCity"
                                                :rules=[validations.required]
                                                item-text="codeStateDescriptionCity"
                                                item-value="id"
                                                clearable
                                                outlined
                                                dense
                                            ></v-combobox>
                                        </v-col>
                                        <v-col 
                                            cols="8"
                                            lg="4"
                                            md="4"
                                            sm="8"
                                        >
                                            <label>Subdivision</label>
                                            <v-text-field
                                                v-model="subdivision"
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col  
                                            cols="12"
                                            lg="3"
                                            md="3"
                                            sm="12"
                                        >
                                            <label>Is it a Gated Community?</label>
                                            <v-radio-group 
                                                v-model="isGatedCommunity"
                                                @change="clearCommunityFields()"
                                                row
                                            >
                                                <v-radio
                                                    label="Yes"
                                                    :value="1"
                                                    color="var(--color__main)"
                                                ></v-radio>
                                                <v-radio
                                                    label="No"
                                                    :value="0"
                                                    color="var(--color__main)"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col 
                                            v-if="isGatedCommunity == 1"
                                            cols="12"
                                            lg="5"
                                            md="5"
                                            sm="12"
                                        >
                                            <label>Gated Community</label>
                                            <v-text-field
                                                v-model="gatedCommunity"
                                                :rules=[validations.required]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col 
                                            v-if="isGatedCommunity == 1"
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            sm="12"
                                        >
                                            <label>Gated Community Details</label>
                                            <v-text-field
                                                v-model="communityDetails"
                                                :rules=[validations.required]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col  
                                            cols="12"
                                            lg="3"
                                            md="3"
                                            sm="12"
                                        >
                                            <label>Dog On Site?</label>
                                            <v-radio-group 
                                                v-model="dogOnSite"
                                                row
                                            >
                                                <v-radio
                                                    label="Yes"
                                                    :value="1"
                                                    color="var(--color__main)"
                                                ></v-radio>
                                                <v-radio
                                                    label="No"
                                                    :value="0"
                                                    color="var(--color__main)"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="5"
                                            sm="12"
                                        >
                                            <label>Gate Code</label>
                                            <v-text-field
                                                v-model="gateCode"
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col  
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>Category</label>
                                            <v-combobox
                                                v-model="addressCategorySelected"
                                                :items="listAddressCategory"
                                                :rules=[validations.required]
                                                item-text="description"
                                                item-value="id"
                                                clearable
                                                outlined
                                                dense
                                            ></v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <div style="width: 100%; text-align: center;">
                                    <v-btn
                                        class="mx-2 buttonCancelDialog"
                                        outlined
                                        @click="showDialogAddress = false"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                        class="mx-2"
                                        outlined
                                        @click="saveAddress()"
                                    >
                                        Save
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-tab-item>

                <!-- CONTACT  -->
                <v-tab-item key="2">
                    <br />
                    <v-row>
                        
                        <v-col
                            style="text-align: right"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="showDialogContactAndClearFields()"
                                    >
                                        Add New Contact
                                    </v-btn>
                                </template>
                                <span>Add New Contact</span>
                            </v-tooltip>
                        </v-col>

                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersContact"
                                :items="customerRequest.listContactRequest"
                                no-data-text="No contact registered"
                                hide-default-footer
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.id" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true
                                        }"
                                        @customActionEdit="showDialogContactAndFillFields"
                                        @confirmDelete="confirmContactDelete" />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-dialog
                        v-model="showDialogContact"
                        transition="dialog-top-transition"
                        persistent
                        width="800"
                        :fullscreen="$vuetify.breakpoint.mobile"
                    >
                    
                        <v-card>
                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                Contact
                            </v-card-title>

                            <v-card-text style="margin-top: 20px;">
                                <v-form
                                    ref="formContact"
                                    v-model="validFormContact"
                                    lazy-validation
                                >
                                    <v-row>
                                        <v-col  
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            sm="5"
                                        >
                                            <label>Category</label>
                                            <v-combobox
                                                v-model="contactCategorySelected"
                                                :items="listContactCategory"
                                                :rules=[validations.required]
                                                item-text="description"
                                                item-value="id"
                                                clearable
                                                outlined
                                                @change="contact = null"
                                                dense
                                            ></v-combobox>
                                        </v-col>
                                        <v-col 
                                            v-if="contactCategorySelected != null && contactCategorySelected.description != undefined && contactCategorySelected.description.toLowerCase().includes('phone')"
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            sm="12"
                                        >
                                            <label>Contact Phone</label>
                                            <v-text-field
                                                key="contactPhone"
                                                v-model="contact"
                                                :rules=[validations.required]
                                                v-mask="'+1 (###) ###-####'"
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col 
                                            v-else-if="contactCategorySelected != null && contactCategorySelected.description.toLowerCase().includes('email')"
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            sm="12"
                                        >
                                            <label>Contact Email</label>
                                            <v-text-field
                                                key="contactEmail"
                                                v-model="contact"
                                                :rules=[validations.required,validations.email]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col 
                                            v-else
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            sm="12"
                                        >
                                            <label>Contact</label>
                                            <v-text-field
                                                key="contactDefault"
                                                v-model="contact"
                                                :rules=[validations.required]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Title</label>
                                            <v-text-field
                                                v-model="type"
                                                :rules=[validations.required]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <div style="width: 100%; text-align: center;">
                                    <v-btn
                                        class="mx-2 buttonCancelDialog"
                                        style=""
                                        outlined
                                        @click="showDialogContact = false"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                        class="mx-2"
                                        outlined
                                        @click="saveContact()"
                                    >
                                        Save
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-tab-item>

                <!-- NOTES  -->
                <v-tab-item key="3">
                    <br />
                    <v-row>
                        <v-col
                            style="text-align: right"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="showDialogNoteAndClearFields()"
                                    >
                                        Add New Note
                                    </v-btn>
                                </template>
                                <span>Add New Note</span>
                            </v-tooltip>
                        </v-col>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersNote"
                                :items="customerRequest.listNoteRequest"
                                no-data-text="No notes registered"
                                hide-default-footer
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.id" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true
                                        }"
                                        @customActionEdit="showDialogNoteAndFillFields"
                                        @confirmDelete="confirmNoteDelete" />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-dialog
                        v-model="showDialogNote"
                        transition="dialog-top-transition"
                        persistent
                        width="800"
                        :fullscreen="$vuetify.breakpoint.mobile"
                    >
                    
                        <v-card>
                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                Note
                            </v-card-title>

                            <v-card-text style="margin-top: 20px;">
                                <v-form
                                    ref="formNote"
                                    v-model="validFormNote"
                                    lazy-validation
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>New Note</label>
                                            
                                            <v-textarea
                                                v-model="note"
                                                :rules=[validations.required]
                                                single-line
                                                rows="3"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <div style="width: 100%; text-align: center;">
                                    <v-btn
                                        class="mx-2 buttonCancelDialog"
                                        style=""
                                        outlined
                                        @click="showDialogNote = false"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                        class="mx-2"
                                        outlined
                                        @click="saveNote()"
                                    >
                                        Save
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-tab-item>

                <!-- DOCUMENTS  -->
                <v-tab-item key="4">
                    <v-row>
                        <v-col
                            cols="12"
                            style="text-align: right"
                        >
                            <canvas id="canvas" hidden></canvas>

                            <v-btn 
                                @click="triggerFileInput" 
                                large
                                color="var(--color__main)"
                                style="color: white;"
                            >
                                Upload
                            </v-btn>

                            <v-file-input
                                v-model="documentsListFiles"
                                ref="fileInput"
                                accept=".*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                :hide-input="true"
                                @change="addFilesToList(documentsListFiles, documentsFiles)"
                            >
                            </v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-simple-table
                                fixed-header
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Name
                                            </th>
                                            <th class="text-left">
                                                Created On
                                            </th>
                                            <th class="text-left">
                                                Created By
                                            </th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in documentsFiles.filter(ane => ane.deleted == 0)" :key="index"
                                        >
                                            <td class="text-left">{{ item.name }}</td>
                                            <td class="text-left">{{ item.createdOn }}</td>
                                            <td class="text-left">{{ item.userName }}</td>
                                            <td v-if="item.id != 0">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip 
                                                            link 
                                                            color="var(--color__main)"
                                                            style="color: white;"
                                                            v-on="on"
                                                            small 
                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.name)"
                                                        >
                                                            Download
                                                        </v-chip>
                                                    </template>
                                                    <span>Click to download [{{ item.name }}]</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-if="item.id != 0">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip 
                                                            link 
                                                            color="var(--color__cinza_escuro)"
                                                            style="color: white;"
                                                            v-on="on"
                                                            small 
                                                            @click="confirmFileDelete(item.id, item.name)"
                                                        >
                                                            Delete
                                                        </v-chip>
                                                    </template>
                                                    <span>Click here to delete this document</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <!-- HISTORY  -->
                <v-tab-item key="5">
                    <br />
                    <v-row>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersHistory"
                                :items="customerRequest.listHistoryRequest"
                                :items-per-page="10"
                                no-data-text="No history registered"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <!-- CALENDAR HISTORY -->
                <v-tab-item 
                    style="padding: 20px;"
                    key="8"
                >
                    <br />
                    <v-row>                    
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Quick Search</label>
                            <v-form @submit.prevent="getCalendarHistoryRegister">
                                <v-text-field
                                    v-model="filterCalendarHistory.fastSearch"
                                    prepend-inner-icon="mdi mdi-magnify"
                                    outlined
                                    single-line
                                    @click:prepend-inner="getCalendarHistoryRegister"
                                    dense
                                ></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-data-table
                                :headers="headersCalendarHistory"
                                :items="filteredCalendarHistorySearch"
                                :items-per-page="10"
                                no-data-text="No history registered"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>

            <br />

            <ActionButtons
                :request="customerRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT, URL_ATTACHMENT } from "@/utilities/Enums";

	import * as moment from 'moment';

    export default ({

        mixins: [Helpers],

        components: {
            ActionList,
            ActionButtons,
            ActionDialog
        },

        data: () => ({

            URL_ATTACHMENT,

            validForm: true,
            validFormAddress: true,
            validFormContact: true,
            validFormNote: true,
            
            tab: null,

            //ADDRESS
            showDialogAddress: false,
            isGatedCommunity: 0,
            address: "",
            stateSelected: null,
            citySelected: null,
            subdivision: "",
            zipCode: "",
            phone: "",
            email: "",
            gatedCommunity: "",
            communityDetails: "",
            gateCode: "",
            dogOnSite: 0,
            addressCategorySelected: null,
            itemAddress: null,

            //CONTACT
            showDialogContact: false,
            contact: "",
            contactCategorySelected: null,
            type: "",
            itemContact: null,

            //NOTE
            showDialogNote: false,
            note: "",
            itemNote: null,

            customerRequest: {
                id: 0,
                name: "",
                idCustomerOrigin: 0,
                customerOriginSelected: null,

                listAddressRequest: [],
                listContactRequest: [],
                listNoteRequest: [],
                listHistoryRequest: []
            },

            documentsListFiles: [],
            documentsFiles: [],

            listCustomerOrigin: [],
            listAddressCategory: [],
            listContactCategory: [],
            listState: [],
            listCity: [],

            headersAddress: [
                { text: "Address", value: "address", sortable: true },
                { text: "City", value: "cityDescription", sortable: true },
                { text: "Zip Code", value: "zipCode", sortable: true },
                { text: "Category", value: "categoryDescription", sortable: true },
                { text: "Is Gated Community", value: "isGatedCommunityDescription", sortable: true },
                { text: "Gated Community", value: "gatedCommunity", sortable: true },
                { text: "Community Details", value: "communityDetails", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            headersContact: [
                { text: "Contact", value: "description", sortable: true },
                { text: "Category", value: "categoryDescription", sortable: true },
                { text: "Title", value: "type", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            headersNote: [
                { text: "Date", value: "dateRegisterFormatted", sortable: true },
                { text: "Username", value: "userName", sortable: true },
                { text: "Note", value: "description", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            headersHistory: [
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Created By", value: "userName", sortable: true },
                { text: "Department", value: "profileName", sortable: true },
                { text: "Subject", value: "description", sortable: true },
            ],

            //CALENDAR HISTORY
            filterCalendarHistory: {
                fastSearch: '',
            },
            listCalendarHistory: [],
            headersCalendarHistory: [
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Created By", value: "userNameRegister", sortable: true },
                { text: "Job Id", value: "idServiceFormatted", sortable: true },
                { text: "Description", value: "description", sortable: true },
            ],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            loading: false,
            
            validations: {
                required: required,
                email: validEmail
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredCalendarHistorySearch: function() {
                return this.listCalendarHistory.filter((historyFilter) => {
                    
                    const filter = this.filterCalendarHistory.fastSearch.toLowerCase();

                    const dateRegisterFormatted = historyFilter.dateRegisterFormatted != null && historyFilter.dateRegisterFormatted != undefined ? historyFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    const userNameRegister = historyFilter.userNameRegister != null && historyFilter.userNameRegister != undefined ? historyFilter.userNameRegister.toLowerCase().match(filter): ""
                    const description = historyFilter.description != null && historyFilter.description != undefined ? historyFilter.description.toLowerCase().match(filter): ""
                    const id = historyFilter.id.toString().match(filter);

                    if (dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(userNameRegister != null) { return userNameRegister; } 
                    else if(description != null) { return description; } 
                    else { return id; }
                });
            },
        },

        methods: {
            
            triggerFileInput() {
                this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
            },

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            const name = itemFile.name;
                            const type = itemFile.type;

                            const item = {
                                id: 0,
                                file: itemFile,
                                name: name,
                                type: type,
                                deleted: false,
                            }

                            files.push(item);
						}	
					});
                }
            },

            async uploadFile(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.CUSTOMER.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.documentsFiles.forEach(async itemFile => {

                    let result = await this.uploadFile(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.CUSTOMER_DOCUMENTS.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.CUSTOMER_DOCUMENTS.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] occurs this error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Error!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async loadDocuments() {
            
                const listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
                    typeAttachment: TYPE_ATTACHMENT.CUSTOMER.value, 
                    typeDocument: TYPE_DOCUMENT.CUSTOMER_DOCUMENTS.value, 
                    idKey: this.id
                });

                listAttachments.forEach(itemFile => {

                    this.documentsFiles.push({
                        id: itemFile.id,
                        file: new File([], itemFile.fileName),
                        fileType: itemFile.fileType,
                        name: itemFile.fileName,
                        createdOn: itemFile.dateRegisterFormatted,
                        userName: itemFile.userNameRegister,
                        deleted: false
                    })
                })
            },

            confirmFileDelete(id, fileName) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file: ${fileName}, confirm your decision?`,
                    methodConfirm: this.deleteFile,
                    params: id
                };
            },

            deleteFile(id) {
                
                this.showLoading();

                const attachment = this.documentsFiles.filter(atc => atc.id == id)[0];

                if (attachment != null && attachment != undefined) {
                    attachment.deleted = 1;
                }

                this.hideLoading();
            },

            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("customerModule/GetById", this.id);

                    if (response.success) {
                        this.customerRequest = response.result;
                        this.customerRequest.listAddressRequest = this.customerRequest.listAddressResponse;
                        this.customerRequest.listContactRequest = this.customerRequest.listContactResponse;
                        this.customerRequest.listNoteRequest = this.customerRequest.listNoteResponse;
                        this.customerRequest.listHistoryRequest = this.customerRequest.listHistoryResponse;

                        this.clearFieldsAddress();

                        this.clearFieldsContact();

                        this.clearFieldsNote();

                        this.loadDocuments();
                    }
                }
            },

            async getListCityByState() {
                
                this.citySelected = null;

                let idState = 0;

                if (this.stateSelected != null && this.stateSelected != undefined) {
                    idState = this.stateSelected.id;
                }

                this.listCity = await this.$store.dispatch("cityModule/List", idState);
            },

            async getLists() {
                
                this.listAddressCategory = await this.$store.dispatch("addressCategoryModule/List");
                this.listContactCategory = await this.$store.dispatch("contactCategoryModule/List");
                this.listState = await this.$store.dispatch("stateModule/List");
                this.listCustomerOrigin = await this.$store.dispatch("customerOriginModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/customer/customerList" });
            },

            //ADDRESS
            async clearFieldsAddress() {
                
                this.address = " ";
                this.stateSelected = {
                    id: 13,
                    codeDescription: "FL - Florida"
                }
                this.citySelected = " ";
                this.subdivision = " ";
                this.zipCode = " ";
                this.phone = " ";
                this.email = " ";
                this.isGatedCommunity = 0;
                this.gatedCommunity = " ";
                this.communityDetails = " ";
                this.gateCode = " ";
                this.dogOnSite = 0;
                this.addressCategorySelected = " ";
                this.itemAddress = null;
                this.getListCityByState();
            },

            async showDialogAddressAndClearFields()  {

                this.clearFieldsAddress();
                this.showDialogAddress = true;
            },

            async showDialogAddressAndFillFields(id) {
                this.clearFieldsAddress();
                this.itemAddress = this.customerRequest.listAddressRequest.filter(adr => adr.id == id)[0];

                if (this.itemAddress != null && this.itemAddress != undefined) {
                    
                    this.address = this.itemAddress.address;

                    this.stateSelected = {
                        id: this.itemAddress.idState,
                        codeDescription: this.itemAddress.stateDescription
                    };

                    await this.getListCityByState();

                    this.citySelected = {
                        id: this.itemAddress.idCity,
                        codeStateDescriptionCity: this.itemAddress.cityDescription
                    };

                    this.zipCode = this.itemAddress.zipCode;
                    this.phone = this.itemAddress.phone;
                    this.email = this.itemAddress.email;
                    this.isGatedCommunity = this.itemAddress.isGatedCommunity;
                    this.gatedCommunity = this.itemAddress.gatedCommunity;
                    this.communityDetails = this.itemAddress.communityDetails;
                    this.gateCode = this.itemAddress.gateCode;
                    this.dogOnSite = this.itemAddress.dogOnSite;
                    this.addressCategorySelected = {
                        id: this.itemAddress.idAddressCategory,
                        description: this.itemAddress.categoryDescription
                    };
                    this.subdivision = this.itemAddress.subdivision;

                    this.showDialogAddress = true;
                }
            },

            async clearCommunityFields() {
                if (this.isGatedCommunity == 0) {
                    this.gatedCommunity = "";
                    this.communityDetails = "";
                }
            },

            async saveAddress() {

                await this.$refs.formAddress.validate();

                if (this.validFormAddress === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the address form. Please review!");
                    return false;
                }
                else {

                    var existsAddress = this.customerRequest.listAddressRequest.filter(adr => adr.address === this.address);

                    if (existsAddress.length > 0 && this.itemAddress == null) {
                        this.showToast("error", "Warning!", "Address already registered!");
                    }
                    else {
                        
                        if (this.itemAddress == null) {
                            this.customerRequest.listAddressRequest.push({
                                id: Math.random(),
                                idAddressCategory: this.addressCategorySelected.id,
                                categoryDescription: this.addressCategorySelected.description,
                                address: this.address.trim(),
                                idState: this.stateSelected.id,
                                stateDescription: this.stateSelected.codeDescription,
                                idCity: this.citySelected.id,
                                cityDescription: this.citySelected.codeStateDescriptionCity,
                                zipCode: this.zipCode.trim(),
                                subdivision: this.subdivision.trim(),
                                isGatedCommunity: this.isGatedCommunity,
                                isGatedCommunityDescription: this.isGatedCommunity == 1 ? "YES": "NO",
                                gatedCommunity: this.isGatedCommunity == 1 ? this.gatedCommunity.trim() : "",
                                communityDetails: this.isGatedCommunity == 1 ? this.communityDetails.trim() : "",
                                gateCode: this.gateCode.trim(),
                                dogOnSite: this.dogOnSite,
                                newRegister: true
                            })
                        }
                        else {
                            
                            this.itemAddress.idAddressCategory = this.addressCategorySelected.id;
                            this.itemAddress.categoryDescription = this.addressCategorySelected.description;
                            this.itemAddress.address = this.address.trim();
                            this.itemAddress.idState = this.stateSelected.id,
                            this.itemAddress.stateDescription = this.stateSelected.codeDescription,
                            this.itemAddress.idCity = this.citySelected.id;
                            this.itemAddress.cityDescription = this.citySelected.codeStateDescriptionCity;
                            this.itemAddress.zipCode = this.zipCode.trim();
                            this.itemAddress.isGatedCommunity = this.isGatedCommunity;
                            this.itemAddress.isGatedCommunityDescription = this.isGatedCommunity == 1 ? "YES" : "NO";
                            this.itemAddress.gatedCommunity = this.isGatedCommunity == 1 ? this.gatedCommunity.trim() : "";
                            this.itemAddress.communityDetails = this.isGatedCommunity == 1 ? this.communityDetails.trim() : "";
                            this.itemAddress.gateCode = this.gateCode;
                            this.itemAddress.dogOnSite = this.dogOnSite;
                            this.itemAddress.subdivision = this.subdivision.trim();
                        }

                        this.showDialogAddress = false;
                    }
                }
            },

            confirmAddressDelete(id) {

                let index = 0
                this.customerRequest.listAddressRequest.forEach(itemAddress => {
                    if (itemAddress.id == id) {

                        this.customerRequest.listAddressRequest.splice(index, 1);
                    }
                    index++;

                });
            },

            //CONTACT
            async clearFieldsContact() {

                this.contact = "";
                this.contactCategorySelected = null;
                this.type = "";
                this.itemContact = null;
            },

            async showDialogContactAndClearFields()  {

                this.clearFieldsContact();
                this.showDialogContact = true;
            },

            async showDialogContactAndFillFields(id) {
                this.clearFieldsContact();
                this.itemContact = this.customerRequest.listContactRequest.filter(con => con.id == id)[0];

                if (this.itemContact != null && this.itemContact != undefined) {
                    
                    this.contact = this.itemContact.description;
                    this.contactCategorySelected = {
                        id: this.itemContact.idContactCategory,
                        description: this.itemContact.categoryDescription
                    };
                    this.type = this.itemContact.type;

                    this.showDialogContact = true;
                }
            },

            async saveContact() {

                await this.$refs.formContact.validate();

                if (this.validFormContact === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the contact form. Please review!");
                    return false;
                }
                else {

                    var existsContact = this.customerRequest.listContactRequest.filter(con => con.description === this.contact && con.idContactCategory == this.contactCategorySelected.id);

                    if (existsContact.length > 0 && this.itemContact == null) {
                        this.showToast("error", "Warning!", "Contact already registered!");
                    }
                    else {
                        
                        if (this.itemContact == null) {
                            this.customerRequest.listContactRequest.push({
                                id: Math.random(),
                                idContactCategory: this.contactCategorySelected.id,
                                categoryDescription: this.contactCategorySelected.description,
                                description: this.contact.trim(),
                                type: this.type.trim(),
                                newRegister: true
                            })
                        }
                        else {
                            this.itemContact.idContactCategory = this.contactCategorySelected.id;
                            this.itemContact.categoryDescription = this.contactCategorySelected.description;
                            this.itemContact.description = this.contact.trim();
                            this.itemContact.type = this.type.trim();
                        }

                        this.showDialogContact = false;
                    }
                }
            },

            confirmContactDelete(id) {

                let index = 0
                this.customerRequest.listContactRequest.forEach(itemContact => {
                    if (itemContact.id == id) {

                        this.customerRequest.listContactRequest.splice(index, 1);
                    }
                    index++;
                });
            },

            //NOTE
            async clearFieldsNote() {

                this.note = "";
                this.itemNote = null;
            },

            async showDialogNoteAndClearFields()  {

                this.clearFieldsNote();
                this.showDialogNote = true;
            },

            async showDialogNoteAndFillFields(id) {
                this.clearFieldsNote();
                this.itemNote = this.customerRequest.listNoteRequest.filter(not => not.id == id)[0];

                if (this.itemNote != null && this.itemNote != undefined) {
                    
                    this.note = this.itemNote.description;

                    this.showDialogNote = true;
                }
            },

            async saveNote() {

                await this.$refs.formNote.validate();

                if (this.validFormNote === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the notes form. Please review!");
                    return false;
                }
                else {

                    if (this.itemNote == null) {
                        this.customerRequest.listNoteRequest.push({
                            id: Math.random(),
                            dateRegisterFormatted: moment().format("MM/DD/YYYY HH:MM"),
                            userName: this.userLoggedGetters.name,
                            description: this.note.trim(),
                            newRegister: true
                        })
                    }
                    else {
                        this.itemNote.description = this.note.trim();
                    }

                    this.showDialogNote = false;
                }
            },

            confirmNoteDelete(id) {

                let index = 0
                this.customerRequest.listNoteRequest.forEach(itemNote => {
                    if (itemNote.id == id) {

                        this.customerRequest.listNoteRequest.splice(index, 1);
                    }
                    index++;
                });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    if (await this.saveFiles()) {
                        this.loading = true;
                        this.showLoading();

                        this.customerRequest.id = this.id;
                        
                        //ADDRESS
                        let listNewAddress = this.customerRequest.listAddressRequest.filter (adr => adr.newRegister === true);
                        if (listNewAddress != null && listNewAddress != undefined) {
                            listNewAddress.forEach (itemAddress => {
                                itemAddress.id = 0
                            })
                        }
                        
                        //CONTACT
                        let listNewContact = this.customerRequest.listContactRequest.filter (adr => adr.newRegister === true);
                        if (listNewContact != null && listNewContact != undefined) {
                            listNewContact.forEach (itemContact => {
                                itemContact.id = 0
                            })
                        }
                        
                        //NOTE
                        let listNewNote = this.customerRequest.listNoteRequest.filter (adr => adr.newRegister === true);
                        if (listNewNote != null && listNewNote != undefined) {
                            listNewNote.forEach (itemNote => {
                                itemNote.id = 0
                            })
                        }

                        if (this.customerRequest.customerOriginSelected != null && this.customerRequest.customerOriginSelected != undefined) {
                            this.customerRequest.idCustomerOrigin = this.customerRequest.customerOriginSelected.id;
                        }

                        const result = await this.$store.dispatch("customerModule/CreateUpdate", this.customerRequest);

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            },

            async getCalendarHistoryRegister() {
                
                const scheduleListHistoryRequest = {
                    idService: 0,
                    idServiceProject: 0,
                    idCustomer: this.id
                }

                this.listCalendarHistory = await this.$store.dispatch("scheduleModule/ListHistory", scheduleListHistoryRequest);
            },
        },

        created() {
            this.showLoading();

            this.getRegister();
            this.getLists();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .formDialog {
        border-bottom: solid 1px var(--color__cinza); 
        background-color: '#ffffff';
    }

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>